.react-autosuggest__input {
    display: block;
    width: 100%;
    font-weight: 400;
    color: #6e707e;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d1d3e2;
    border-radius: 0.35rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;

    height: calc(1.5em + 0.5rem + 2px);
    padding: 0.25rem 0.5rem;
    font-size: .875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}

.react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    background-color: #fff;
    border: 1px solid #aaa;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    width: 96%;
    z-index: 2;
}
.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
}
.react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
}